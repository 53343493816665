/* Global */
html,
body {
  height: 100%;
  min-height: 100%;
}
body {
  font-family: "Slalom Sans", sans-serif;
  margin: 0;
  box-sizing: border-box;
  font-size: 16px;
  width: 100vw;
  height: 100vh;
  color: #525252;
}

/* Type */
p {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.2;
}

/* Medium */
@media (min-width: 800px) {
  p {
    font-size: 1rem;
  }
}

/* Large */
@media (min-width: 1024px) {
  p {
    font-size: 1.25rem;
  }
}

.p--small {
  font-size: 0.85rem;
}

/* Layout */
/* Medium */
.container {
  height: 100%;
}

@media (min-width: 800px) {
  .container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    align-items: center;
    height: 100%;
  }
}

.login-content {
  display: grid;
  grid-area: 1 / 1 / 2 / 6;
  height: 100%;
  align-items: center;
}

/* Large */
@media (min-width: 1024px) {
  .login-content {
    display: grid;
    grid-area: 1 / 1 / 2 / 6;
    height: 100%;
    align-items: center;
  }
}

.login-content--inner {
  max-width: 24rem;
  margin: 0 auto;
  padding: 2rem;
}

/* Large */
@media (min-width: 1024px) {
  .login-content--inner {
    max-width: 24rem;
    margin: 0 auto;
    padding: 1rem;
  }
}

.login-background {
  display: grid;
  grid-area: 1 / 6 / 2 / 13;
  background-image: url("../media/landing-page-image--cropped-mobile.jpg");
  background-position: 90% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 50%;
}

@media (min-width: 640px) {
  .login-background {
    background-image: url("../media/landing-page-image--cropped.jpg");
    height: 100%;
  }
}

/* Components */
.logo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 2rem;
  max-width: 300px;
}

.logo img {
  max-width: 100%;
}

/* Medium */
@media (min-width: 800px) {
  .logo {
    max-width: 280px;
  }
}

/* Large */
@media (min-width: 1024px) {
  .logo {
    max-width: 100%;
  }
}

.logo svg {
  width: 100%;
  height: auto;
}

.login-info {
  padding-left: 50px;
}

/* Medium */
@media (min-width: 800px) {
  .login-info {
    padding-left: 47px;
  }
}

/* Large */
@media (min-width: 1024px) {
  .login-info {
    padding-left: 65px;
  }
}

.login--copy {
  margin-bottom: 2rem;
}

.btn {
  position: relative;
  margin: 0 auto;
  padding: 15px 25px;
  border: 2px solid #0c62fb;
  border-radius: 2px;
  font-family: inherit;
  color: #0c62fb;
  font-weight: 500;
  font-size: 1em;
  line-height: 25px;
  cursor: pointer;
  touch-action: manipulation;
  -webkit-tap-highlight-color: #0c62fb;
  outline: none;
  background-color: #FFF;
}

.btn:hover {
  background-color: #0C62FB;
  color: #FFF;
  border-color: #0C62FB;
}

.btn:after {
  content: none;
  position: absolute;
  top: -4px;
  left: -4px;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  box-shadow: 0 0 0 3px #F5BD41;
  border-radius: 2px;
  z-index: 0;
}

.btn:active {
  background-color: #B6D0FE;
  color: #0C62FB;
  border-color: #0C62FB;
}

.btn:focus {
  background-color: #0C62FB;
  color: #FFF;
  border-color: #0C62FB;
}

.btn:focus:after {
  content: "";
}

.btn:disabled {
  background-color: #DFE1E5;
  border-color: #DFE1E5;
  color: #6C7384;
}

footer {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
}

/* Medium */
@media (min-width: 800px) {
  footer {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
}

/* Medium */
@media (min-width: 800px) {
  .footer-left {
    display: grid;
    grid-area: 1 / 1 / 2 / 6;
    align-items: center;
    text-align: center;
  }

  .footer-right {
    display: grid;
    grid-area: 1 / 6 / 2 / 13;
  }
}

.footer-list {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
  list-style: none;
  margin: 0;
}

.footer-list li {
  display: inline-block;
  margin: 0 0.5rem;
}

.footer-list li a {
  color: #525252;
  text-decoration: none;
}

.footer-list li a:hover {
  text-decoration: underline;
}

/* Desktop */
@media (min-width: 1024px) {
  .btn {
    padding: 19px 25px;
    font-size: 1.429em;
    line-height: 25px;
    transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
  }

  footer {
    font-size: 1em;
  }
}

/* PRIVACY POLICY */
/*================ Rich Text Editor ================*/
.container--privacy {
  width: 100%;
  max-width: 42rem;
  margin: 0 auto;
  padding: 3rem 1rem;
}

.rte p {
  font-size: 1rem;
}

.rte ul,
.rte ol {
  margin: 0 0 1rem 1rem;
  list-style-position: outside;
}

.rte ul li {
  list-style-position: outside;
  padding-left: 1.5rem;
  padding-bottom: 1rem;
}

.rte ul {
  list-style: disc outside;
}

.rte ul ul {
  list-style: circle outside;
  padding: 1rem 0 0 0;
  margin: 0 0 0 1rem;
}
